html 

/* .form {
    display: flex;
    flex-direction: co;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    min-width: 80vw;
    width : 100vw;
} */

.formName { 
    padding: 10%;

}
.form { 
    display: inline-block;
}